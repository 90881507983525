import React, { useEffect } from 'react';

const Logout = () => {
    useEffect(() => {
        doLogout();
    }, []);

    const doLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("ssoToken");
        localStorage.removeItem("userType");
        localStorage.removeItem("userProfile");
        window.location.href = "/login";
    }
    return (
        <div>
            <h1>Logout</h1>
        </div>
    );
}

export default Logout;
