import Axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isDesktop, isAndroid } from "react-device-detect";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Cookies from 'js-cookie'
import Captcha from "../components/Captcha";
import Globalprovider from "../context/GlobalProvider";
import Globalcontext from "../context/GlobalContext";

const API_URL = process.env.REACT_APP_API_URL;
const SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const GOOGLE_WEBVIEW_ID = process.env.REACT_APP_GOOGLE_WEBVIEW_ID;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = () => {
  const query = useQuery();
  const returnURL = query.get("returnurl");
  const loginType = query.get("loginType");
  const decodedReturn = decodeURIComponent(returnURL);

  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("webview");
  const [error, setError] = useState({ enabled: false, message: "" });
  const [isReferralLogin, setIsReferralLogin] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  useEffect(() => {
    checkLoginType();
    const token = localStorage.getItem("token");
    const ssoToken = localStorage.getItem("ssoToken");
    const userType = localStorage.getItem("userType");

    if (token && returnURL) {
      verifyToken(token, returnURL)
      // window.location.href = `/refer-and-earn`;
    } else if (ssoToken && returnURL) {
      verifyToken(ssoToken, `${decodedReturn}?ssoToken=${ssoToken}`);
      // window.location.href = `${decodedReturn}?ssoToken=${ssoToken}`;
    } else if (ssoToken) {
      verifyToken(ssoToken, `https://learn.mathongo.com/s/mycourses?ssoToken=${ssoToken}`);
      // window.location.href = `https://learn.mathongo.com/s/mycourses?ssoToken=${ssoToken}`;
    }
  }, []);

  const doLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("ssoToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userProfile");
    window.location.href = "/login";
  }

  const verifyToken = async (token, redirectUrl = "/") => {
    try {
      const response = await Axios.get(`${API_URL}/v1/auth/users/verify`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 401) {
        window.location.href = redirectUrl
      } else {
        doLogout()
      }
    } catch (error) {
      console.log(error);
      doLogout()
    }
  };

  const checkLoginType = () => {
    const path = window.location.pathname + window.location.search;
    if (path.includes("refer") || path.includes("wallet")) {
      setIsReferralLogin(true);
    } else {
      setIsReferralLogin(false);
    }
  }

  const handleLogin = async (googleData) => {
    if (googleData && !googleData?.error) {
      setLoadingGoogle(true);
      setError({ enabled: false, message: "" });

      Axios.post(`${API_URL}/v1/auth/login/google`, {
        token: googleData.tokenId,
      })
        .then((res) => {
          const { token, user } = res.data;
          if (user) localStorage.setItem('userProfile', JSON.stringify(user))
          if (!isAndroid && returnURL && returnURL.length > 0) {
            console.log("returning to: ", decodedReturn);
            localStorage.setItem("userType", "refer")
            localStorage.setItem("ssoToken", token);
            window.location.href = `${decodedReturn}?ssoToken=${token}`;
          } else {
            console.log("no return url");
            localStorage.setItem("userType", "courses")
            localStorage.setItem("ssoToken", token);
            window.location.href = `https://learn.mathongo.com/s/mycourses?ssoToken=${token}`;
          }
        })
        .catch((err) => {
          setLoadingGoogle(false);
          setError({
            enabled: true,
            message:
              "Email doesn't exist. While logging in with Google, please use the Gmail ID used while purchasing the course.",
          });
        });
    }
  };

  const handleLoginManual = async (formData) => {
    if (captchaToken.length > 0) {
      setLoading(true);
      setError({ enabled: false, message: "" });

      const { email, password } = formData;

      Axios.post(`${API_URL}/v1/auth/login/manual`, {
        email,
        password,
        captchaToken,
      })
        .then((res) => {
          const { token, user } = res.data;
          if (user) localStorage.setItem('userProfile', JSON.stringify(user))
          if (!isAndroid && returnURL && returnURL.length > 0) {
            console.log("returning to: ", decodedReturn);
            localStorage.setItem("userType", "refer")
            localStorage.setItem("ssoToken", token);
            window.location.href = `${decodedReturn}?ssoToken=${token}`;
          } else {
            console.log("no return url");
            localStorage.setItem("userType", "courses")
            localStorage.setItem("ssoToken", token);
            window.location.href = `https://learn.mathongo.com/s/mycourses?ssoToken=${token}`;
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.message === "User not found")
            setError({
              enabled: true,
              message:
                "Email doesn't exist. Please check your email again or contact us at support@mathongo.com",
            });
          else if (err?.response?.data?.message === "Wrong password")
            setError({
              enabled: true,
              message: "Wrong password. Please check your password and retry",
            });
        });
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITEKEY}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <section>
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center gx-0">
            <div className="col-12 col-md-8 col-lg-6 pb-6 pt-4">
              {loginType && loginType === 'inf' ? (
                <h1 className="fw-bold text-center mb-5">Influencer Login</h1>
              ) : (
                <h1 className="fw-bold text-center mb-5">{isReferralLogin ? 'Refer & Earn Login' : 'Courses Login'}</h1>
              )}

              {error.enabled ? (
                <p className="d-flex justify-content-center text-danger">
                  {error.message}
                </p>
              ) : null}

              <div className="card shadow pb-3">
                {!isDesktop ? null : (
                  <div className="card-header pt-2 pb-3">
                    {loadingGoogle ? (
                      <div className="d-flex justify-content-center">
                        <div className="my-3 spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <GoogleLogin
                          clientId={GOOGLE_CLIENT_ID}
                          buttonText="Log in with Google"
                          cookiePolicy="single_host_origin"
                          onSuccess={handleLogin}
                          onFailure={handleLogin}
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              className={`btn w-100 btn-primary my-3 d-flex justify-content-center align-items-center`}
                            >
                              <img src="/img/google.svg" className="mx-1" />
                              &nbsp; &nbsp;<span>Login with Google</span>
                            </button>
                          )}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="card-body pt-5">
                  {isDesktop && (
                    <p className="text-center mb-4 text-muted">
                      or Log in with Email ID and Password
                    </p>
                  )}
                  <form
                    className="mt-2"
                    role="form"
                    onSubmit={handleSubmit(handleLoginManual)}
                  >
                    <div className="form-group form-floating">
                      <label className="form-label" htmlFor="email">
                        {" "}
                        Email
                      </label>
                      <input
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                        autoComplete="off"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="name@address.com"
                      />
                      {errors && errors?.email?.type == "required" && (
                        <span className="invalid-feedback d-block mt-2">
                          This field is required
                        </span>
                      )}
                      {errors && errors?.email?.type == "pattern" && (
                        <span className="invalid-feedback d-block mt-2">
                          Please enter a valid email
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="form-label">Password</label>
                        </div>
                      </div>

                      <div className="input-group input-group-merge">
                        <input
                          {...register("password", {
                            required: true,
                          })}
                          name="password"
                          type={passVisible ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter your password"
                          style={{
                            fontFamily: "monospace",
                          }}
                        />

                        <span
                          style={{ cursor: "pointer" }}
                          className="input-group-text px-4 cursor-pointer"
                          onClick={() => setPassVisible(!passVisible)}
                        >
                          <img src="/img/eye.svg" height={20} />
                        </span>
                      </div>

                      {errors && errors?.password?.type == "required" && (
                        <span className="invalid-feedback d-block mt-2">
                          This field is required
                        </span>
                      )}
                    </div>

                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <div className="mt-6 spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <button
                        disabled={!(isValid && captchaToken.length > 0)}
                        className={`btn w-100 btn-primary mt-4 ${isValid && captchaToken.length > 0 ? "" : "disabled"
                          }`}
                      >
                        Log in
                      </button>
                    )}

                    <div className="mt-5 mb-0 d-flex justify-content-center align-items-center">
                      <Captcha setToken={setCaptchaToken} />
                    </div>

                    <p className="small text-muted text-center mt-5 mb-0">
                      Forgot password? <Link to="/forgot">Click here</Link>
                    </p>
                  </form>
                </div>
              </div>

              <p className="mt-6 mb-0 fs-sm text-center text-muted">
                Facing issues?{" "}
                <a href="mailto:support@mathongo.com?Subject=Email from SSO Login Page">
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
