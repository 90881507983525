import axios from "axios";
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useContext } from "react";

import {
    BiWalletAlt,
    BiUserPlus,
} from 'react-icons/bi';
import {
    Button,
} from 'react-bootstrap'

import styles from './refer.module.css';
import Referredusers from "../../components/ReferAndEarn/ReferredUsers";
import Statcard from "../../components/ReferAndEarn/StatCard";
import Referralitem from "../../components/ReferAndEarn/ReferralItem";
import Globalcontext from "../../context/GlobalContext";

const API_URL = process.env.REACT_APP_API_URL;

const ReferAndEarn = () => {
    const [testSeriesCount, setTestSeriesCount] = useState(0)
    const [courseCount, setCourseCount] = useState(0)
    const [comboCount, setComboCount] = useState(0)
    const { userProfile } = useContext(Globalcontext)
    const [userReferral, setUserReferral] = useState(null)
    const [showItems, setShowItems] = useState(false)

    useEffect(() => {
        fetchUserReferral()
    }, []);

    useEffect(() => {
        let testCnt = 0;
        let courseCnt = 0;
        let comboCnt = 0;
        userReferral?.referralItems?.forEach(item => {
            if (item?.itemId?.itemType === 'testSeries') {
                testCnt += 1;
            } else if (item?.itemId?.itemType === 'course') {
                courseCnt += 1;
            } else {
                comboCnt += 1;
            }
        })
        setTestSeriesCount(testCnt)
        setCourseCount(courseCnt)
        setComboCount(comboCount)
    }, [userReferral]);

    const fetchUserReferral = async () => {
        const token = localStorage.getItem('token')
        if (!token) return;
        axios.get(`${API_URL}/v1/users/referral`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                if (response.status === 200) {
                    setUserReferral(response.data.data)
                }
            },
            error => {
                console.log('Unable to fetch user referral')
                console.log(error)
            }
        ).catch(error => {
            console.log('Unable to fetch user referral')
            console.log(error)
        })
    }
    return (
        <div>
            <Helmet>
                <title>Refer and Earn Program | MathonGo</title>
                <meta name="description" content="Refer & Earn program" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <div className="container">
                <div className="row" style={{
                    borderRadius: "12px",
                    boxShadow: '0px 4px 24px 0px #00000014'
                }}>
                    <div className="col-12 col-md-8">
                        <div className="card-body p-4">
                            <h4 className={`${styles.header2} mb-5`}>
                                Invite {userProfile && userProfile.isInfluencer ? ('Students') : ('your friends')} and get <span className="text-danger">cashback</span>
                            </h4>
                            <div className="row mt-7">
                                <div className="col-6 col-md-4 text-center">
                                    <img className="mb-3" src="/img/Group 1.svg" alt="step 1" height="50px" width="50px" />
                                    <h5 className={`${styles.refer_header}`}>Refer your friends</h5>
                                    <p className="text-muted">
                                        Just share your referral link
                                    </p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    <img className="mb-3" src="/img/Group 2.svg" alt="step 1" height="50px" width="50px" />
                                    <h5 className={`${styles.refer_header}`}>Your friends join us</h5>
                                    <p className="text-muted">
                                        They receive some amount off
                                    </p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    <img className="mb-3" src="/img/Group 3.svg" alt="step 1" height="50px" width="50px" />
                                    <h5 className={`${styles.refer_header}`}>You make savings!</h5>
                                    <p className="text-muted">
                                        You make some amount, easily withdraw it!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 d-none d-lg-block" style={{
                        backgroundImage: 'url("/img/undraw_social_friends_re_7uaa%201.svg")',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>

                    </div>
                </div>
                <div className="row mt-7 mb-4">
                    <div className="col-12 px-0">
                        <div className="d-flex">
                            <div className={`${styles.rounded_icon} shadow`}>
                                <img className={`${styles.inner_icon}`} src="/img/Shape.svg" height="16px" width="16px" alt="note icon" />
                            </div>
                            <h4 className={`${styles.header2} mt-3 ml-2`}>Your Referral Overview</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 ml-7">
                        <p className="font-weight-bold mb-1">
                            Number of Users Referred
                        </p>
                        <p className={`${styles.number_stat}`}>
                            {userReferral?.referredUsers?.length}
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 ml-7">
                        <p className="font-weight-bold mb-1">
                            Total Earnings
                        </p>
                        <p className={`${styles.number_stat}`}>
                            {userReferral?.referredUsers?.reduce((a,v) =>  a = a + v.earningAmount , 0 )}
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 ml-7">
                        <p className="font-weight-bold mb-1">
                            Detailed Report
                        </p>
                        <p className={`${styles.number_stat}`}>
                            <Button variant="success" size="xs" href="/wallet/">
                                <BiWalletAlt className="mb-1" /> Open Wallet
                            </Button>
                        </p>
                    </div>
                </div>

                {/*  */}
                <div className="row mt-7 mb-4">
                    <div className="col-12 px-0">
                        <div className="d-flex">
                            <div className={`${styles.rounded_icon} shadow`}>
                                <img className={`${styles.inner_icon}`} src="/img/Shape.svg" height="16px" width="16px" alt="note icon" />
                            </div>
                            <h4 className={`${styles.header2} mt-3 ml-2`}>Your Referral Links</h4>
                        </div>
                    </div>
                </div>
                {/* Course */}
                {courseCount > 0 && (
                    <>
                        <div className="row">
                            <h4 className={`${styles.header2} mt-3 ml-2`}>Courses:</h4>
                        </div>
                        <div className="row">
                            {userReferral?.referralItems?.map(item => {
                                if (item?.itemId?.itemType === 'course') {
                                    return (
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <Referralitem item={item} studentCode={userReferral?.studentCode} />
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </>
                )}
                {/* Test series */}
                {testSeriesCount > 0 && (
                    <>
                        <div className="row">
                            <h4 className={`${styles.header2} mt-3 ml-2`}>Test Series:</h4>
                        </div>
                        <div className="row">
                            {userReferral?.referralItems?.map(item => {
                                if (item?.itemId?.itemType === 'testSeries') {
                                    return (
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <Referralitem item={item} studentCode={userReferral?.studentCode} />
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </>
                )}
                
                {/* Combo Courses */}
                {comboCount > 0 && (
                    <>
                        <div className="row">
                            <h4 className={`${styles.header2} mt-3 ml-2`}>Courses:</h4>
                        </div>
                        <div className="row">
                            {userReferral?.referralItems?.map(item => {
                                if (item?.itemId?.itemType === 'courseTestSeriesCombo') {
                                    return (
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <Referralitem item={item} studentCode={userReferral?.studentCode} />
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </>
                )}
            </div>
        </div >
    );
}

export default ReferAndEarn;
