import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import EmailUpdationSuccess from "../components/EmailUpdationSuccess";

const API_URL = process.env.REACT_APP_API_URL;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmailUpdate = () => {
  const query = useQuery();
  const token = query.get("token");
  const Router = useHistory();

  const [isUpdated, setIsUpdated] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    setError,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    updateProfile(data);
  };

  const updateProfile = async (data) => {
    axios
      .patch(`${API_URL}/v1/users/update-email`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (response) => {
          setIsUpdated(true);
        },
        (error) => {
          setIsInvalidToken(true);
        }
      )
      .catch((e) => {
        setIsInvalidToken(true);
      });
  };

  const fetchUserProfile = async () => {
    axios
      .get(`${API_URL}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setUserProfile(response.data.user);
          }
        },
        (error) => {
          Router.push("/oops");
        }
      )
      .catch((err) => {
        Router.push("/oops");
      });
  };

  useEffect(() => {
    if (token) {
      fetchUserProfile();
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title>Update Email | MathonGo</title>
      </Helmet>
      <section>
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center gx-0">
            <div className="col-12 col-md-8 pb-6 pt-4">
              <h1 className="fw-bold text-center">Update Email ID</h1>
              {!isUpdated ? (
                <>
                  <p className="mb-6 text-center">
                    You have given an incorrect email id while purchasing your
                    course/test series and we can't send log in details on that
                    id. Please enter a correct email where you can receive the
                    details.
                  </p>
                  <p class="mb-5">
                    Incorrect email given by you:{" "}
                    <span class="text-danger font-weight-bold">
                      {userProfile?.email}
                    </span>
                  </p>
                  <div className="card shadow pb-4">
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="email">
                            {" "}
                            Correct Email <span className="text-danger">*</span>
                          </label>
                          <input
                            {...register("newEmail", {
                              required: true,
                              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              validate: {
                                emailMatch: (value) =>
                                  value != userProfile?.email ||
                                  "Email confirmation error!",
                              },
                            })}
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter your correct email id"
                          />
                          {errors.newEmail?.type === "required" && (
                            <span className="text-danger small mt-2">
                              This field is required.
                            </span>
                          )}
                          {errors.newEmail?.type === "pattern" && (
                            <span className="text-danger small mt-2">
                              Please enter a correct email id.
                            </span>
                          )}
                          {errors.newEmail?.type === "emailMatch" && (
                            <span className="text-danger small mt-2">
                              The new email can&apos;t be same the old email.
                            </span>
                          )}
                          {/* <p className="text-danger small mt-2">Please enter a correct email id.</p> */}
                        </div>
                        <div className="form-group">
                          <label className="form-label" htmlFor="emailConfirm">
                            {" "}
                            Confirm Email <span className="text-danger">*</span>
                          </label>
                          <input
                            {...register("confirmEmail", {
                              required: true,
                              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              validate: {
                                emailMatched: (value) =>
                                  value === getValues().newEmail ||
                                  'Email id doesn"t match.',
                              },
                            })}
                            type="email"
                            className="form-control"
                            id="emailConfirm"
                            placeholder="Confirm your correct email id"
                          />
                          {errors.confirmEmail?.type === "required" && (
                            <p className="text-danger small mt-2">
                              This field is required.
                            </p>
                          )}
                          {errors.confirmEmail?.type === "emailMatched" && (
                            <p className="text-danger small mt-2">
                              Email id doesn&apos;t match.
                            </p>
                          )}
                        </div>
                        <button
                          className="btn w-100 btn-primary mt-4 mb-0"
                          type="submit"
                          disabled={!isValid}
                        >
                          Update Email
                        </button>
                        {isInvalidToken ? (
                          <p className="text-danger mt-2">
                            Token has expired, you can&apos;t change your email.
                          </p>
                        ) : null}
                      </form>
                    </div>
                  </div>
                </>
              ) : null}

              {isUpdated ? (
                <EmailUpdationSuccess email={getValues().newEmail} />
              ) : null}
              <p className="mt-6 mb-0 fs-sm text-center text-muted">
                Facing issues?{" "}
                <a href="mailto:support@mathongo.com?Subject=Email from IAM Email Update Page">
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailUpdate;
