import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Captcha from "../components/Captcha";

const API_URL = process.env.REACT_APP_API_URL;
const SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [success, setSuccess] = useState({ enabled: false, message: "" });
  const [error, setError] = useState({ enabled: false, message: "" });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const onSubmit = async (formData) => {
    setLoading(true);
    setError({ enabled: false, message: "" });

    const { email } = formData;
    Axios.post(`${API_URL}/v1/auth/forgotpass`, { email, captchaToken })
      .then((res) => {
        if (res.status == 200) {
          setSuccess({ enabled: true, message: email });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.message === "User not found")
          setError({
            enabled: true,
            message:
              "Email doesn't exist. Please check your email again or contact us at support@mathongo.com",
          });
      });
    console.log(formData);
  };

  useEffect(() => {
    if (window.navigator.userAgent.includes("wv")) setCaptchaToken("webview");
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITEKEY}>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <section>
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center gx-0">
            <div className="col-12 col-md-8 col-lg-6 pb-6 pt-4">
              <h1 className="fw-bold text-center mb-8">Forgot Password</h1>

              {error.enabled ? (
                <p className="mb-5 d-flex justify-content-center text-danger">
                  {error.message}
                </p>
              ) : null}

              {success.enabled ? (
                <div className="card shadow">
                  <div className="card-body">
                    <p className="mb-0">
                      We've sent your password to your registered email id{" "}
                      <span className="text-primary">{success.message}</span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="card shadow">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group form-floating">
                        <label className="form-label" htmlFor="email">
                          {" "}
                          Registered Email
                        </label>
                        <input
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                          autoComplete="off"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="name@address.com"
                        />
                        {errors && errors?.email?.type == "required" && (
                          <span className="invalid-feedback d-block mt-2">
                            This field is required
                          </span>
                        )}
                        {errors && errors?.email?.type == "pattern" && (
                          <span className="invalid-feedback d-block mt-2">
                            Please enter a valid email
                          </span>
                        )}
                      </div>
                      {loading ? (
                        <div className="d-flex justify-content-center">
                          <div className="mt-6 spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <button
                          className={`btn w-100 btn-primary mt-4 ${
                            isValid ? "" : "disabled"
                          }`}
                        >
                          Receive Password
                        </button>
                      )}

                      <div className="mt-5 mb-0 d-flex justify-content-center align-items-center">
                        <Captcha setToken={setCaptchaToken} />
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <p className="mt-6 mb-0 fs-sm text-center text-muted small">
                Facing issues?{" "}
                <a href="mailto:support@mathongo.com?Subject=Email from Forgot Password Page">
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </GoogleReCaptchaProvider>
  );
};

export default ForgotPassword;
