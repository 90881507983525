import moment from 'moment';
import React, { useState, useEffect } from 'react';
import {
    Table
} from 'react-bootstrap';

const useQuery = () => {
    return new URLSearchParams(window.location.search);
};

const Wallethistory = ({ history }) => {
    const query = useQuery();

    const [reqId, setReqId] = useState(query.get('reqId'))

    const getWithdrawStatus = (user) => {
        if (user?.status === 'SUCCESS') {
            return <span className="text-success">Success</span>
        } else if (user?.status === 'FAILED') {
            return <span className="text-danger">Failed</span>
        } else if (user?.status === 'PENDING') {
            return <span className="text-primary">Pending</span>
        } else {
            return <span className="text-info">Processing</span>
        }
    }
    return (
        <>
            <h4 className="">Withdrawal History</h4>
            <Table hover bordered>
                <thead>
                    <tr className="text-center">
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Transfered To</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {history?.map(withdraw => (
                        <tr className="text-center" style={{
                            backgroundColor: withdraw?._id === reqId ? 'rgba(22, 28, 45, 0.075)' : ''
                        }}>
                            <td className="text-muted">{moment(withdraw?.createdAt).format('lll')}</td>
                            <td className="text-success font-weight-bold">₹{withdraw?.amount}</td>
                            <td lassName="text-muted">{withdraw?.upiId}</td>
                            <td className="font-weight-bold">
                                {getWithdrawStatus(withdraw)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {history?.length === 0 ? (
                <div className="text-center text-success">No Records Found.</div>
            ) : null}
        </>
    );
}

export default Wallethistory;
