import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Index = () => {
  const router = useHistory();

  useEffect(() => {
    router.push("/login");
  }, []);

  return <div></div>;
};

export default Index;
