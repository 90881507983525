import React from "react";

const Oops = () => {
  return (
    <div>
      <section>
        <div className="container d-flex flex-column">
          <div className="row d-flex align-items-center justify-content-center mt-12">
            <div className="col-12 col-md-5 col-lg-4">
              <h1 className="display-3 fw-bold text-center">Oops!</h1>

              <p className="mb-5 text-center text-muted">
                We ran into an issue, but don’t worry, we’ll take care of it for
                sure.
              </p>

              <div className="text-center">
                <a className="btn btn-primary" href="https://www.mathongo.com">
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Oops;
