import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  BiLogOutCircle,
  BiLogInCircle,
  BiWalletAlt,
  BiMoney,
  BiUser,
  BiHome,
} from 'react-icons/bi';
import {
  Nav,
  Navbar,
  NavDropdown
} from 'react-bootstrap'
import Globalcontext from '../context/GlobalContext'

const FRONTEND_URL = process.env.REACT_FRONTEND_URL || 'https://iam.mathongo.com';
// const FRONTEND_URL = process.env.REACT_FRONTEND_URL || 'http://localhost:3000';

const ToolNav = () => {
  const { showMessage, userProfile } = useContext(Globalcontext)
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    login()
  }, []);

  const login = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }

  const logout = async () => {
    showMessage({
      title: 'Logged out.',
      color: 'danger'
    })
    localStorage.removeItem('token')
    localStorage.removeItem('ssoToken')
    setIsLoggedIn(!isLoggedIn)
    window.location.href = `/login?returnurl=/refer-and-earn`
  }

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="shadow-sm fixed-top" >
        <div className="container-md">
          <a className="navbar-brand mr-auto" href="https://www.mathongo.com">
            <img
              src="/img/logo-dark.svg"
              width="150"
              height="40"
              className="navbar-brand-img"
              alt="Logo"
            />
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{
            maxHeight: '100% !important'
          }}>
            <Nav className="ml-auto">
              <a className="nav-link active" href="/"><BiHome className="mb-1" /> Home <span className="sr-only">(current)</span></a>
              {isLoggedIn ? (
                <>
                  {userProfile && !userProfile?.isInfluencer && (
                    <Nav.Link href="/profile">
                      <BiUser className="mb-1" /> Profile
                    </Nav.Link>
                  )}
                  <Nav.Link href="/wallet">
                    <BiWalletAlt className="mb-1" /> Wallet
                  </Nav.Link>
                  <Nav.Link href="/refer-and-earn">
                    <BiMoney className="mb-1" /> Refer & Earn
                  </Nav.Link>
                  <Nav.Link onClick={logout}>
                    <BiLogOutCircle className="mb-1" /> Logout
                  </Nav.Link>
                </>
              ) : (
                <Nav.Link href={`/login?returnurl=/refer-and-earn`}>
                  <BiLogInCircle className="mb-1" /> Login to Refer & Earn
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/* <nav className="navbar navbar-expand-lg navbar-light shadow-sm fixed-top">
        <div className="container-md">
          <a className="navbar-brand mr-auto" href="https://www.mathongo.com">
            <img
              src="/img/logo-dark.svg"
              width="150"
              height="40"
              className="navbar-brand-img"
              alt="Logo"
            />
          </a>
          <a className="nav-item nav-link active" href="/"><BiHome className="mb-1" /> Home <span className="sr-only">(current)</span></a>
          {isLoggedIn ? (
            <>
              <a className="nav-item nav-link" href="/profile">
                <BiUser className="mb-1" /> Profile</a>
              <a className="nav-item nav-link" href="/wallet">
                <BiWalletAlt className="mb-1" /> Wallet</a>
              <a className="nav-item nav-link" href="/refer-and-earn">
                <BiMoney className="mb-1" /> Refer & Earn</a>
              <a className="nav-item nav-link" href="#" onClick={logout}><BiLogOutCircle className="mb-1" /> Logout</a>
            </>
          ) : (
            <a className="nav-item nav-link" href={`/login?returnurl=${FRONTEND_URL}/wallet`}><BiLogInCircle className="mb-1" /> Login to Wallet</a>
          )}
        </div>
      </nav> */}
    </div>
  );
};

export default ToolNav;
