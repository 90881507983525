import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Profile = () => {
  const query = useQuery();
  const Router = useHistory();
  const [userProfile, setUserProfile] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "all",
  });
  const [isInvalidPage, setIsInvalidPage] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const token = query.get("token");
  const storageToken = localStorage.getItem('token')

  const onSubmit = (data) => {
    updateProfile(data);
  };

  const updateProfile = async (data) => {
    axios
      .patch(`${API_URL}/v1/users`, data, {
        headers: {
          Authorization: `Bearer ${token || storageToken}`,
        },
      })
      .then(
        (response) => {
          setIsUpdated(true);
          setModalShow(true);
        },
        (error) => {
          Router.push("/oops");
        }
      )
      .catch((err) => {
        setIsUpdated(false);
        Router.push("/oops");
      });
  };

  const fetchUserProfile = async () => {
    axios
      .get(`${API_URL}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token || storageToken}`,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setValue("name", response.data.user?.name);
            setValue("email", response.data.user?.email);
            setValue("mobileNumber", response.data.user?.mobileNumber);
            setValue("fathersName", response.data.user?.fathersName);
            setValue(
              "fathersMobileNumber",
              response.data.user?.fathersMobileNumber
            );
            setValue(
              "fathersOccupation",
              response.data.user?.fathersOccupation
            );
            setValue("country", response.data.user?.country);
            setValue("state", response.data.user?.state);
            setValue("city", response.data.user?.city);
            setValue("address", response.data.user?.address);
            setValue("classStudying", response.data.user?.classStudying);
            setValue("referredBy", response.data.user?.referredBy);
            setUserProfile(response.data.user);
          }
        },
        (error) => {
          Router.push("/oops");
        }
      )
      .catch((err) => {
        Router.push("/oops");
      });
  };

  useEffect(() => {
    if (token || storageToken) {
      fetchUserProfile();
    }
  }, [token, storageToken]);

  return (
    <div>
      <header className="bg-dark pt-lg-8 pb-lg-10 pt-6 pb-9" style={{
        marginTop: '-20px'
      }}>
        <div className="container-md">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="fw-bold text-white mb-0">Account Settings</h2>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-8 mt-n6">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="card shadow mb-6">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="name">
                            Name
                          </label>
                          <input
                            {...register("name", {})}
                            className="form-control"
                            id="name"
                            type="text"
                            placeholder="John Doe"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            {...register("email", {})}
                            className="form-control"
                            id="email"
                            type="email"
                            placeholder="john.doe@email.com"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="mobile">
                            Mobile
                          </label>
                          <input
                            {...register("mobileNumber", {})}
                            className="form-control"
                            id="mobile"
                            type="text"
                            placeholder="7978849212"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="country">
                            Country
                          </label>
                          <input
                            {...register("country", {})}
                            className="form-control"
                            id="country"
                            type="text"
                            placeholder="India"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="state">
                            State
                          </label>
                          <input
                            {...register("state", {})}
                            className="form-control"
                            id="state"
                            type="text"
                            placeholder="Karnataka"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="city">
                            City
                          </label>
                          <input
                            {...register("city", {})}
                            className="form-control"
                            id="city"
                            type="text"
                            placeholder="Bengaluru"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="fullAddress">
                            Full Address <span className="text-danger">*</span>
                          </label>
                          <textarea
                            autoFocus
                            {...register("address", { required: true })}
                            className="form-control"
                            id="fullAddress"
                            rows="3"
                          ></textarea>
                          {errors.address?.type === "required" && (
                            <p className="text-danger small mt-2">
                              Please enter your full address.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mb-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="classStudying">
                            Which class are you in?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            {...register("classStudying", { required: true })}
                            className="form-control"
                            id="classStudying"
                            aria-label="class"
                          >
                            <option value="" selected>
                              Select an option:
                            </option>
                            <option value="class12">Class 12</option>
                            <option value="firstTimeDropper">
                              1st Time Dropper
                            </option>
                            <option value="secondTimeDropper">
                              2nd Time Dropper
                            </option>
                            <option value="partialDropper">
                              Partial Dropper
                            </option>
                            <option value="class10">Class 10</option>
                            <option value="class11">Class 11</option>
                          </select>
                          {errors.classStudying?.type === "required" && (
                            <p className="text-danger small mt-2">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mb-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="referredBy">
                            How did you get to know about us?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            {...register("referredBy", { required: true })}
                            className="form-control"
                            id="referredBy"
                            aria-label="reference"
                          >
                            <option value="" selected>
                              Select an option:
                            </option>
                            <option value="mathongoYoutubeChannel">
                              MathonGo YouTube Channel
                            </option>
                            <option value="anotherYoutubeChannel">
                              Another YouTube Channel
                            </option>
                            <option value="advertisement">Advertisement</option>
                            <option value="quora">Quora</option>
                            <option value="googleSearch">Google Search</option>
                            <option value="mathongoApp">MathonGo App</option>
                            <option value="friend">Through My Friend</option>
                            <option value="teacher">Through My Teacher</option>
                          </select>
                          {errors.referredBy?.type === "required" && (
                            <p className="text-danger small mt-2">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="fatherName">
                            Father&apos;s Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            {...register("fathersName", { required: true })}
                            className="form-control"
                            id="fatherName"
                            type="text"
                            placeholder="Enter your father's name"
                          />
                          {errors.fathersName?.type === "required" && (
                            <p className="text-danger small mt-2">
                              Please enter your father&apos;s name.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="fatherMobile">
                            Father&apos;s Mobile{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            {...register("fathersMobileNumber", {
                              required: true,
                              pattern: /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/,
                            })}
                            className="form-control"
                            id="fatherMobile"
                            type="tel"
                            placeholder="Enter your father's mobile"
                          />
                          {errors.fathersMobileNumber?.type === "required" && (
                            <p className="text-danger small mt-2">
                              This field is required.
                            </p>
                          )}
                          {errors.fathersMobileNumber?.type === "pattern" && (
                            <p className="text-danger small mt-2">
                              Please enter a correct mobile number.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mb-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="fatherOccupation"
                          >
                            Father's Occupation{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            {...register("fathersOccupation", {
                              required: true,
                            })}
                            className="form-control"
                            id="fatherOccupation"
                            type="text"
                            placeholder="Enter your father's occupation"
                          />
                          {errors.fathersOccupation?.type === "required" && (
                            <p className="text-danger small mt-2">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 mt-5">
                        <button
                          className="btn w-100 btn-primary"
                          disabled={!isValid}
                          type="submit"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <p className="text-center mb-0">
                <small className="text-muted">
                  If you&apos;re facing any issue,{" "}
                  <a href="mailto:support@mathongo.com?Subject=Email from Account Settings Page">
                    email to support@mathongo.com
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Profile Saved</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile details have been saved.</Modal.Body>
      </Modal>
    </div>
  );
};

export default Profile;
