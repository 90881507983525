import React, { useCallback } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const ReCaptchaComponent = ({ setToken }) => {
  const handleReCaptchaVerify = useCallback((token) => {
    setToken(token);
  }, []);

  return <GoogleReCaptcha onVerify={handleReCaptchaVerify} />;
};

export default ReCaptchaComponent;
