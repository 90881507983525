import React from "react";

const EmailUpdationSuccess = ({ email }) => {
  return (
    <>
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0">
          <div class="col-12 col-md-8 pb-6 pt-4">
            <h4 class="text-success text-center mt-10">
              Email updated successfully
            </h4>
            <p class="mb-3 text-center">
              We've updated your email to{" "}
              <span class="font-weight-bold text-danger">{email}</span>.<br />
              Please check your inbox for login details.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailUpdationSuccess;
