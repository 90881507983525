import axios from "axios";
import { useLocation } from "react-router";
import React, { useState, useEffect } from "react";

import Router from "./router";
import Nav from "./components/Nav";

import "./styles/css/theme.min.css";
import "./styles/Global.module.css"
import Globalprovider from "./context/GlobalProvider";

const API_URL = process.env.REACT_APP_API_URL;
const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

const unAuthenticatedRoutes = [
  "/email-update",
  "/profile"
 ]

const App = () => {
  const query = useQuery();
  const returnURL = query.get("returnurl");
  const decodedReturn = decodeURIComponent(returnURL);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (unAuthenticatedRoutes.includes(currentPath)) {
      return;
    }
    let token = localStorage.getItem('token');
    if (!token) {
      const ssoToken = query.get("ssoToken");
      if (!ssoToken) {
        redirectToLoginPage();
      } else {
        axios.get(`${API_URL}/v1/auth/users/verify`, {
          headers: {
            'Authorization': `Bearer ${ssoToken}`
          }
        }).then(
          response => {
            let { token } = response.data;
            localStorage.setItem('token', token)
            redirectToLoggedInPage();
          },
          error => {
            redirectToLoginPage();
          }
        )
      }
    }
  }, []);

  const redirectToLoggedInPage = () => {
    window.location.href = '/refer-and-earn';
  }

  const redirectToLoginPage = () => {
    if (window.location.pathname !== "/login") {
      if (returnURL) {
        window.location.href = `/login?returnurl=${returnURL}`
      } else {
        window.location.href = "/login"
      }
    }
  }

  return (
    <Globalprovider>
      <Nav />
      <div className="mt-10 pt-5">
        <Router />
      </div>
    </Globalprovider>
  );
}

export default App;
