import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Index from "./pages/index";
import Login from "./pages/login";
import Forgot from "./pages/forgot";
import Profile from "./pages/profile";
import UpdateEmail from "./pages/email-update";
import Oops from "./pages/oops";
import ReferAndEarn from "./pages/RreferAndEarn/refer-and-earn";
import ReferralLinks from "./pages/RreferAndEarn/referral-links";
import Wallet from "./pages/wallet/wallet";
import Withdraw from "./pages/wallet/withdraw";
import Influencer from "./pages/in/influencer";
import Logout from './pages/logout';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/email-update" component={UpdateEmail} />
        <Route exact path="/refer-and-earn" component={ReferAndEarn} />
        <Route exact path="/refer-and-earn/links" component={ReferralLinks} />
        <Route exact path="/wallet" component={Wallet} />
        <Route exact path="/wallet/withdraw" component={Withdraw} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/in/:id" component={Influencer} />
        <Route path="*" component={Oops} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
