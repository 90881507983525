import React from 'react';
import { Helmet } from 'react-helmet';
import InfluencerItem from '../../components/ReferAndEarn/InfluencerItem';

const Influencer = () => {
    return (
        <div>
            <Helmet>
                <title>Influencer Program | MathonGo</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
                    <div className="col-12 col-sm-12 col-md-6">
                        <div className="card shadow">
                            <div className="user text-center">
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '-50px',
                                        left: '40%',
                                        height: '90px',
                                        width: '90px',
                                        border: '3px solid #ccc',
                                        borderRadius: '50%'
                                    }}
                                    className="profile"
                                >
                                    <img style={{ marginTop: '2px' }} src="https://i.imgur.com/JgYD2nQ.jpg" className="rounded-circle" height="80px" width="80px" />
                                </div>
                            </div>
                            <div className="mt-7 text-center">
                                <h4 className="mb-0 mt-5">Alan Cooper</h4>
                                <span className="text-muted d-block mb-2">@alancooper</span>
                                <h5 className="">New York</h5>
                                {/* <button className="btn btn-primary btn-sm follow">Follow</button> */}
                                <p className="text-muted px-10 my-3">
                                    Full stack developer, avid reader, love to take a long walk, swim.
                                </p>
                                <div className="d-flex justify-content-between align-items-center my-4 px-4">
                                    <div className="stats">
                                        <h6 className="mb-0">Followers</h6> <span>8,797</span>
                                    </div>
                                    <div className="stats">
                                        <h6 className="mb-0">Projects</h6> <span>142</span>
                                    </div>
                                    <div className="stats">
                                        <h6 className="mb-0">Ranks</h6> <span>129</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {[...new Array(3)].map(item => (
                        <div className="col-12 col-sm-6 col-md-4">
                            <InfluencerItem />
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default Influencer;
