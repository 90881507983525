import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import Oops from "../components/Oops";
import Profile from "../components/Profile";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Home = () => {
  const query = useQuery();
  const [tokenFound, setTokenFound] = useState(true);

  const token = query.get("token");
  const storageToken = localStorage.getItem('token')

  useEffect(() => {
    if (token?.length > 0) setTokenFound(true);
    else setTokenFound(false);

    // if (storageToken) {
    //   setTokenFound(true)
    // } else {
    //   setTokenFound(false)
    // }
  }, [token]);

  return (
    <div>
      <Helmet>
        <title>Profile | MathonGo</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main>{tokenFound ? <Profile /> : <Oops />}</main>
    </div>
  );
};

export default Home;
