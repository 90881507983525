import React, { useState, useEffect } from 'react';
import { BiCopy } from 'react-icons/bi';
import copy from 'copy-to-clipboard';
import Globalcontext from '../../context/GlobalContext';
import { useContext } from 'react';

const PAYMENTS_URL = process.env.PAYMENTS_URL || 'https://pay.mathongo.com/';

const Referralitem = ({ item, studentCode }) => {
    const { userProfile, userProfile: { isInfluencer } } = useContext(Globalcontext)
    const [isCopied, setIsCopied] = useState(false)
    const [waText, setWaText] = useState('')
    const [tgText, setTgText] = useState('')

    const plainPaymentLink = `${item?.itemId?.detailsUrl}?coupon=${studentCode}&item_id=${item?.itemId?.paymentPlatformId}`

    const sharingText = `
        Join ${item?.itemId?.title} using my code ${studentCode} and get discount of INR ${item?.discountAmount}. 
        You can click on the link to join: 
    `;
    const whatsAppLink = `${encodeURIComponent(plainPaymentLink + `&utm_source=${isInfluencer ? 'wa_inf' : 'wa'}&utm_medium=referral&utm_campaign=referral`)}`
    const telegramLink = `${encodeURIComponent(plainPaymentLink + `&utm_source=${isInfluencer ? 'tg_inf' : 'tg'}&utm_medium=referral&utm_campaign=referral`)}`
    let copyLink = ``;
    
    if (isInfluencer) {
        copyLink = `${item?.shortUrl}`
    } else {
        copyLink = `${plainPaymentLink}&utm_source=direct&utm_medium=referral&utm_campaign=referral`
    }
    useEffect(() => {
        const { itemId: { referralMessage } } = item;
        if (referralMessage) {
            let shareText = referralMessage;
            shareText = shareText.replace("{studentCode}", studentCode);
            shareText = shareText.replace("{itemDetailsUrl}", whatsAppLink);
            shareText = shareText.replace("{itemTitle}", item?.itemId?.title);
            setWaText(shareText);
        }
        if (referralMessage) {
            let shareText = referralMessage;
            shareText = shareText.replace("{studentCode}", studentCode);
            shareText = shareText.replace("{itemDetailsUrl}", telegramLink);
            shareText = shareText.replace("{itemTitle}", item?.itemId?.title);
            setTgText(shareText);
        }
    }, [item]);
    return (
        <div>
            <div className="mb-7 mt-4" style={{
                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
                borderRadius: '12px'
            }}>
                <div className="card-body p-4">
                    {/* {JSON.stringify(item, null, 4)} */}
                    <div className="d-flex">
                        <img src={item?.itemId?.icon || '/img/Group 1.svg'} alt="icon" height="32px" width="32px" />
                        <p className="mt-1 ml-2" style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#2B2F31'
                        }}>
                            {item?.itemId?.title}
                        </p>
                    </div>
                    <div className="text-uted" style={{
                        fontSize: '14px',
                        opacity: '0.75'
                    }}>
                        {/* <p>{item?.itemId?.tagLine}</p> */}
                        <p>You will get <span className="text-success font-weight-bold">₹{item?.earningAmount}</span> in your wallet.
                            and {userProfile && userProfile?.isInfluencer ? ('the referred student ') : ('your friend')} will get <span className="text-success font-weight-bold">₹{item?.discountAmount + ' '}</span>
                            discount on purchase of this pack.</p>
                    </div>

                    <div className="">
                        <p className="font-weight-bold mb-0"><span class="text-muted">Your Referral Code: </span>{studentCode}</p>
                    </div>

                    <div className="copy-to-clipboard mt-5"
                        onClick={e => {
                            copy(copyLink)
                            setIsCopied(true)
                            setTimeout(() => {
                                setIsCopied(false)
                            }, 3000);
                        }}
                        style={{
                            cursor: 'pointer',
                            border: '1px dashed #484F54',
                            borderRadius: '4px',
                            boxSizing: 'border-box',
                            padding: '20px'
                        }}
                    >
                        <div className="text-center" >
                            <BiCopy className="mb-1" /> Copy to Clipboard
                        </div>
                    </div>
                    {isCopied ? (
                        <p
                            className="text-success my-1"
                            style={{ fontSize: '13px' }}>
                            Invite Link Copied Successfully
                        </p>
                    ) : null}
                    <div className="share-block d-flex justify-content-between mt-5">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Referralitem;
