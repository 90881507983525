import React, { useState, useEffect } from 'react';
import { BiCopy, BiShoppingBag } from 'react-icons/bi';
import {
    Button,
} from 'react-bootstrap'

const InfluencerItem = () => {
    const [isCopied, setIsCopied] = useState(false)
    return (
        <div>
            <div className="card shadow rounded my-7">
                <div className="card-body p-4" style={{
                    // background: "linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(213,244,217,0.8211659663865546) 44%, rgba(183,231,190,0.7343312324929971) 100%, rgba(57,193,75,0) 100%)"
                }}>
                    <h2 className="text-success">JEE Mains 2022 Batch 1</h2>
                    <p className="text-muted" style={{
                        fontSize: '14px'
                    }}>
                        The Course is the Best Course offered by MathonGo till now.
                        Every Chapter has 3 components, recorded video lectures, questions for practice and live doubt clearing sessions.
                    </p>
                </div>
                <div className="card-footer">
                    <Button size="sm" block>
                        <BiShoppingBag className="mb-1" /> Buy Now
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default InfluencerItem;
