import React, { useEffect, useState } from 'react';
import Globalcontext from './GlobalContext';
import {
    Toast,
} from 'react-bootstrap'

const MESSAGE_OPTIONS = {
    show: false,
    title: '',
    message: '',
    color: 'primary',
    timeout: 5
}

const Globalprovider = ({ children }) => {
    const [message, setMessage] = useState(MESSAGE_OPTIONS)
    const [userProfile, setUserProfile] = useState({})

    useEffect(() => {
        let localUserProfile = localStorage.getItem('userProfile')
        if (localUserProfile) setUserProfile(JSON.parse(localUserProfile))
    }, []);

    useEffect(() => {
        let timer;
        if (message.show) {
            timer = setTimeout(() => {
                setMessage(MESSAGE_OPTIONS)
            }, Number(message?.timeout || 5) * 1000);
        } else {
            clearTimeout(timer)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [message]);

    const hideToast = () => setMessage(MESSAGE_OPTIONS)

    const showMessage = (options) => setMessage({ ...options, show: true })
    return (
        <div>
            <Globalcontext.Provider value={{
                message,
                setMessage,
                showMessage,
                userProfile
            }}>
                <Toast color="success" show={message?.show} onClick={hideToast} style={{
                    zIndex: 9999,
                    top: '90px',
                    right: '1vw',
                    position: 'fixed',
                    minWidth: '250px',
                    cursor: 'pointer'
                }}>
                    <Toast.Header closeButton={false} className={`text-${message?.color}`}>
                        <strong className="me-auto">{message?.title}</strong>
                    </Toast.Header>
                    {/* <Toast.Body>{message?.message}</Toast.Body> */}
                </Toast>
                {children}
            </Globalcontext.Provider>
        </div>
    );
}

export default Globalprovider;
