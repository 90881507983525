import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Button,
} from 'react-bootstrap';
import {
    BiWalletAlt,
    BiMoney,
} from 'react-icons/bi';

import Wallethistory from '../../components/Wallet/WalletHistory';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Wallet = () => {
    const [userWallet, setUserWallet] = useState(null);

    useEffect(() => {
        fetchUserWallet()
    }, []);

    const fetchUserWallet = async () => {
        const token = localStorage.getItem('token')
        axios.get(`${API_URL}/v1/users/wallet`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                if (response.status === 200) {
                    setUserWallet(response.data.data)
                }
            },
            error => {
                console.log('Unable to fetch user wallet')
                console.log(error)
            }
        ).catch(error => {
            console.log('Unable to fetch user wallet')
            console.log(error)
        })
    }

    return (
        <div>
            <Helmet>
                <title>Refer and Earn Program | MathonGo</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 border p-3 text-center">
                        <label htmlFor="" className="text-muted d-inline">Total Amount Earned:</label><br/>
                        <h1 className="text-success d-inline">₹{userWallet?.amountAvailable}</h1>
                    </div>
                </div>
                <div className="row my-7 justify-content-center">
                    <div className="col-12 col-sm-9 col-md-7">
                        <div className="card shadow-sm text-center">
                            <div className="card-body p-2">
                                <Button variant="success" size="xs" href="/wallet/withdraw">
                                    <BiWalletAlt className="mb-1" />&nbsp;
                                    Withdraw
                                </Button>&nbsp;
                                <Button size="xs" className="btn btn-primary btn-sm" href="/refer-and-earn">
                                    <BiMoney className="mb-1" />&nbsp;
                                    Refer & Earn
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-7 d-none">
                    <Wallethistory history={userWallet?.withdrawHistory} />
                </div>
            </div>
        </div>
    );
}

export default Wallet;
