import axios from 'axios';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import {
    Button,
} from 'react-bootstrap';
import {
    useForm
} from 'react-hook-form'

const API_URL = process.env.REACT_APP_API_URL;

const Withdraw = () => {
    const [submitData, setSubmitData] = useState({
        success: false,
        message: 'Not Submitted.'
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { register, getValues, handleSubmit } = useForm();
    const [userWallet, setUserWallet] = useState(null);

    useEffect(() => {
        fetchUserWallet()
    }, []);

    const fetchUserWallet = async () => {
        setIsLoading(true)
        const token = localStorage.getItem('token')
        axios.get(`${API_URL}/v1/users/wallet`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                if (response.status === 200) {
                    setUserWallet(response.data.data)
                }
            },
            error => {
                console.log('Unable to fetch user wallet')
                console.log(error)
            }
        ).catch(error => {
            console.log('Unable to fetch user wallet')
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onSubmit = () => {
        setIsSubmitted(false)
        setIsLoading(true)
        const token = localStorage.getItem('token')
        axios.post(
            `${API_URL}/v1/users/wallet/withdraw`,
            getValues(),
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(
                response => {
                    if (response.status === 201) {
                        setSubmitData({
                            success: true,
                            message: 'Successfully placed withdrawal request.'
                        })
                    } else {
                        setSubmitData({
                            success: false,
                            message: response?.data?.data?.message
                        })
                    }
                    setIsSubmitted(true)
                    setIsLoading(false)
                    console.log('Data')
                    console.log(response)
                }
            ).catch(error => {
                console.log('Unable to fetch user referral in catch')
                setSubmitData(error.response.data)
                setIsSubmitted(true)
                setIsLoading(false)
            })
    }
    return (
        <div>
            <Helmet>
                <title>Withdraw Amount | MathonGo</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-9 col-md-6 my-7">
                        <div className="card shadow-sm">
                            <div className="card-body p-5">
                                {(true) ? ( // userWallet?.amountOnHold > 0 || userWallet?.amountAvailable <= 0
                                    <>
                                        <p>You cannot place withdrawal requests from your end. Your earned referral amount is transferred on a monthly basis automatically by our team. So, for e.g. if you earn INR 10,000 from referrals in January, we will deposit INR 10,000 in the first week of February in your account.</p>
                                    </>
                                ) : (
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="upi">
                                                UPI ID
                                            </label>
                                            <input
                                                {...register("upiId", {})}
                                                className="form-control form-control-sm"
                                                id="upi"
                                                type="text"
                                                placeholder="sampleXXXXX@upi"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="confirmUPI">
                                                Confirm UPI ID
                                            </label>
                                            <input
                                                {...register("confirmUPI", {})}
                                                className="form-control form-control-sm"
                                                id="confirmUPI"
                                                type="text"
                                                placeholder="sampleXXXXX@upi"
                                            />
                                        </div>
                                        {isSubmitted ? (
                                            <p className={`${submitData?.success ? 'text-success' : 'text-danger'} text-small`}>
                                                <small>{submitData?.message}</small>
                                            </p>
                                        ) : null}
                                        <div className="text-center mt-7">
                                            <Button className="btn btn-danger btn-xs" href="/wallet">Cancel</Button> &nbsp; &nbsp;
                                            <button className="btn btn-success btn-xs" disabled={isLoading}>Withdraw</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Withdraw;
