import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import {
    Button,
} from 'react-bootstrap';

import Referralitem from "../../components/ReferAndEarn/ReferralItem";

import styles from './links.module.css';

const ReferralLinks = () => {
    return (
        <div>
            <Helmet>
                <title>Refer and Earn Program | MathonGo</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <div className="container">
                <div className="row">
                    {[...new Array(3)].map(item => (
                        <div className="col-12 col-sm-6 col-md-4">
                            <Referralitem />
                        </div>
                    ))}
                </div>
                <div className="text-center">
                    <Button href="/refer-and-earn" size="xs" variant="dark">Go Back</Button>
                </div>
            </div>
        </div>
    );
}

export default ReferralLinks;
